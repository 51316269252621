import React from 'react';

import { PAGES, PAGES_URL } from '../../utils/utils';

const Contact = () => {
  function popPrecisionsSujet(sujet) {
    const display = sujet === 'Autre' ? 'inline-block' : 'none';
    document.getElementById('precisionsSujet').style.display = display;
    //reset field if sujet !== 'autre'
    // document.getElementById(
    //   'precisionsSujet',
    // ).innerHTML = document.getElementById('precisionsSujet').innerHTML;
  }

  function popPrecisionsConnu(connu) {
    const display = [
      'Moteur de recherche',
      'Site web',
      'Medias',
      'Reseaux sociaux',
    ].includes(connu)
      ? 'inline-block'
      : 'none';
    document.getElementById('precisionsConnu').style.display = display;
    //reset field if includes condition is false
    // document.getElementById(
    //   'precisionsConnu',
    // ).innerHTML = document.getElementById('precisionsConnu').innerHTML;
  }

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Contact</h1>
      </div>

      <div id="img-wrap-contact">
        <iframe
          width="759"
          height="365"
          src="https://maps.google.ca/maps?q=lemire+lemire+avocats+joliette&hl=fr&ie=UTF8&sll=45.751055,-73.61063&sspn=0.034976,0.065746&hq=lemire+lemire+avocats&hnear=Joliette,+Qu%C3%A9bec&cid=11202491731402244976&t=m&ll=45.751055,-73.603249&spn=0.017218,0.038581&output=embed&iwloc=near"
        ></iframe>
      </div>

      <div id="info">
        <div id="texteContact">
          <div itemScope itemType="https://schema.org/LocalBusiness">
            <h2>
              Veuillez communiquer avec nous par téléphone, télécopieur,
              courriel, ou en utilisant le formulaire ci-dessous.
            </h2>
            <ul className="colonne1">
              <p itemProp="name" id="contactAlign">
                Lemire Lemire avocats s.e.n.c.r.l.
              </p>
              <li>
                <span itemProp="address">
                  <a
                    href="https://maps.google.ca/maps?q=lemire+lemire+avocats+joliette&hl=fr&ie=UTF8&ll=45.751594,-73.603249&spn=0.017218,0.038581&sll=45.751055,-73.61063&sspn=0.034976,0.065746&hq=lemire+lemire+avocats&hnear=Joliette,+Qu%C3%A9bec&cid=11202491731402244976&t=m&z=15&iwloc=A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2724, Chemin Sainte-Marie
                    <br />
                    Mascouche (Québec) J7K 1N1
                  </a>
                </span>
              </li>
              <br />
            </ul>

            <ul className="colonne2">
              <li>
                Téléphone:{' '}
                <span itemProp="telephone">
                  <a href="tel:+14509661055">450.966.1055</a>
                </span>
              </li>
              <li>
                Télécopieur: <span itemProp="faxNumber">450.966.0193</span>
              </li>
              <li>
                Courriel:{' '}
                <span itemProp="email">
                  <a href="mailto:info@lemireavocat.com">
                    info@lemireavocat.com
                  </a>
                </span>
              </li>
            </ul>
            <br />
          </div>
        </div>

        <div id="form_container">
          <form
            id="contactform"
            method="POST"
            name="Contact FR"
            data-netlify="true"
            action={PAGES_URL.fr[PAGES.MERCI]}
          >
            <input type="hidden" name="form-name" value="Contact FR" />
            <fieldset className="effect8">
              <h1>Formulaire de contact</h1>
              <label className="labLeft" htmlFor="firstname">
                Prénom
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Prénom"
                  required
                />
              </label>
              <label className="labRight" htmlFor="lastname">
                Nom de famille
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Nom de famille"
                  required
                />
              </label>
              <label className="labLeft" htmlFor="courriel">
                Courriel
                <input
                  id="courriel"
                  name="courriel"
                  type="email"
                  placeholder="Courriel"
                  required
                />
              </label>
              <label className="labRight" htmlFor="telnum">
                Numéro de téléphone
                <input
                  id="telnum"
                  name="telnum"
                  type="tel"
                  placeholder="Numéro de téléphone"
                />
              </label>
              <label htmlFor="sujet">
                Sujet
                <select
                  className="customSelect"
                  id="sujet"
                  name="sujet"
                  onChange={(event) => popPrecisionsSujet(event.target.value)}
                  required
                  defaultValue=""
                >
                  <option disabled value="">
                    ---
                  </option>
                  <optgroup label="Juridique">
                    <option value="Séparation-divorce">
                      Séparation-divorce
                    </option>
                    <option value="Médiation">Médiation</option>
                    <option value="Garde d'enfants - Pension alimentaire">
                      Garde d'enfants - Pension alimentaire
                    </option>
                    <option value="Responsabilité civile">
                      Responsabilité civile
                    </option>
                    <option value="Construction">Construction</option>
                    <option value="Assurances">Assurances</option>
                    <option value="Immobilier">Immobilier</option>
                  </optgroup>
                  <optgroup label="Autre">
                    <option value="Commentaires">Commentaires</option>
                    <option value="Média">Média</option>
                    <option value="Autre">Autre</option>
                  </optgroup>
                </select>
              </label>
              <label
                id="precisionsSujet"
                className="precisionsSujet"
                htmlFor="precisionsSujet"
              >
                Veuillez préciser :{' '}
                <input
                  id="precisionsSujet"
                  name="precisionsSujet"
                  type="text"
                  placeholder="Veuillez préciser"
                />
              </label>

              <label htmlFor="description">
                Message
                <textarea
                  id="description"
                  name="description"
                  placeholder="Message"
                  required
                />
              </label>
              <label htmlFor="connu">
                Comment avez-vous connu Lemire Lemire avocats?
                <select
                  className="customSelect"
                  id="connu"
                  name="connu"
                  onChange={(event) => popPrecisionsConnu(event.target.value)}
                  defaultValue=""
                >
                  <option value="" disabled>
                    ---
                  </option>
                  <option value="Amis">Amis</option>
                  <option value="Moteur de recherche">
                    Moteur de recherche
                  </option>
                  <option value="Reseaux sociaux">Réseaux sociaux</option>
                  <option value="Site web">Site web</option>
                  <option value="Medias">Médias</option>
                </select>
              </label>

              <label
                id="precisionsConnu"
                className="precisionsConnu"
                htmlFor="precisionsConnu"
              >
                Veuillez préciser :{' '}
                <input
                  id="precisionsConnu"
                  name="precisionsConnu"
                  type="text"
                  placeholder="Veuillez préciser"
                />
              </label>

              <label className="recevoirCopie" htmlFor="recevoirCopie">
                <input
                  id="recevoirCopie"
                  name="recevoirCopie"
                  type="checkbox"
                />
                <span className="recevoirCopieTexte">
                  Me faire parvenir une copie par courriel.
                </span>
              </label>
              <input type="hidden" name="form_id" value="603721" />
              <input
                id="saveForm"
                className="button_text"
                type="submit"
                name="submit"
                value="Envoyer"
              />
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

Contact.propTypes = {};

export default Contact;
