import React from 'react';

import { PAGES } from '../utils/utils';
import Contact from '../components/fr/contact';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout page={PAGES.CONTACT}>
    <SEO title="Contact" />
    <Contact />
  </Layout>
);

export default ContactPage;
